define('diesel/models/role', ['exports', 'ember-data', 'diesel/models/base', 'ember', 'diesel/models/organization-profile'], function (exports, _emberData, _dieselModelsBase, _ember, _dieselModelsOrganizationProfile) {
  var computed = _ember['default'].computed;

  var Role = _dieselModelsBase['default'].extend({
    name: _emberData['default'].attr(),
    type: _emberData['default'].attr({ defaultValue: 'compliance_user' }),
    organization: _emberData['default'].belongsTo('organization', { async: true }),
    memberships: _emberData['default'].hasMany('membership', { async: true }),
    invitations: _emberData['default'].hasMany('invitations', { async: true }),
    permissions: _emberData['default'].hasMany('permissions', { async: true }),
    users: _emberData['default'].hasMany('users', { async: true }),
    description: computed('name', function () {
      var desc = null;
      var name = this.get('name');

      Object.keys(_dieselModelsOrganizationProfile.ROLE_DESIGNATIONS).forEach(function (designation) {
        var options = _dieselModelsOrganizationProfile.ROLE_DESIGNATIONS[designation];
        if (options.name === name) {
          desc = options.description;
        }
      });

      return desc;
    }),

    labels: _ember['default'].computed('type', function () {
      return ({
        owner: {
          type: 'Billing Admin',
          permissions: 'Users have full Read/Write, including billing and user management',
          global_permissions: ['✅ Billing', '✅ User management', '✅ Set up SSO', '✅ Set up integrations'],
          module_permissions: [{ label: "", items: ['✅ Full read/write'] }]
        },
        platform_owner: {
          type: 'Deploy Admin',
          permissions: 'Can read and manage all Deploy Environments',
          global_permissions: [],
          module_permissions: []
        },
        compliance_owner: {
          type: 'Conveyor Admin',
          permissions: 'Users have full Read/Write, excluding billing',
          global_permissions: ['❌ Billing', '✅ User management', '❌ Set up SSO', '✅ Set up integrations'],
          module_permissions: [{ label: "", items: ['✅ Full read/write'] }]
        },
        platform_user: {
          type: 'Deploy User',
          permissions: 'Can read or manage Deploy Environments depending on the granular permissions applied',
          global_permissions: [],
          module_permissions: []
        },
        compliance_rooms_collaborator: {
          type: 'Trust Sharer',
          permissions: 'Users can invite customers to Rooms. They also have full Read, but can only Write to simple objects that are assigned to them such as vendors, systems, risks, and tickets. They cannot edit or manage Rooms content.',
          global_permissions: ['N/A'],
          module_permissions: [{
            label: "Trust Center",
            items: ['✅ Can invite customers to your Trust Center and approve access requests', '❌ Otherwise cannot edit or manage Trust Center content', '✅ Can view Trust Center content']
          }, {
            label: "Knowledge Base",
            items: ['✅ Can view all Knowledge Base content', '✅ Can create new Knowledge Base content', '✅ Can edit Knowledge Base content they create or to which they are assigned', '❌ Otherwise cannot edit Knowledge Base content']
          }, {
            label: "Questionnaire Eliminator",
            items: ['✅ Can create new questionnaires', '✅ Can view and edit questionnaires they create or to which they are assigned (as the assignee or a collaborator)', '❌ Otherwise, cannot view or edit questionnaires']
          }]
        },
        compliance_user: {
          type: 'Assignee Only',
          permissions: 'Users have full Read, but can only Write to simple objects that are assigned to them such as vendors, systems, risks, and tickets. Note: Since a vendor review is a collection of other objects in the system, Assignee Only users will not be able to complete a vendor review even if they are assigned to one.',
          global_permissions: ['N/A'],
          module_permissions: [{
            label: "Trust Center",
            items: ['❌ Cannot invite customers to Trust Center or approve access requests', '❌ Can see names of documents in your Trust Center, but cannot see their contents', '❌ Otherwise cannot edit or manage Trust Center content']
          }, {
            label: "Knowledge Base",
            items: ['✅ Can view all Knowledge Base content', '✅ Can create new Knowledge Base content', '✅ Can edit Knowledge Base content they create or to which they are assigned', '❌ Otherwise, cannot edit Knowledge Base content']
          }, {
            label: "Questionnaire Eliminator",
            items: ['✅ Can create new questionnaires', '✅ Can view and edit questionnaires they create or to which they are assigned (as the assignee or a collaborator)', '❌ Otherwise, cannot view or edit questionnaires']
          }] }
      })[this.get('type')];
    }),

    isComplianceRole: _ember['default'].computed.match('type', /compliance/),
    isPlatformRole: _ember['default'].computed.match('type', /platform/),

    isAccountOwner: _ember['default'].computed.equal('type', 'owner'),
    isComplianceOwner: _ember['default'].computed.equal('type', 'compliance_owner'),
    isPlatformOwner: _ember['default'].computed.equal('type', 'platform_owner'),
    isComplianceUser: _ember['default'].computed.equal('type', 'compliance_user'),
    isComplianceRoomsCollaborator: _ember['default'].computed.equal('type', 'compliance_rooms_collaborator'),
    isPlatformUser: _ember['default'].computed.equal('type', 'platform_user'),

    isOwner: _ember['default'].computed.or('isAccountOwner', 'isPlatformOwner', 'isComplianceOwner'),
    isUser: _ember['default'].computed.or('isPlatformUser', 'isComplianceRoomsCollaborator', 'isComplianceUser'),

    isPlatform: _ember['default'].computed.or('isPlatformOwner', 'isPlatformUser'),
    isCompliance: _ember['default'].computed.or('isComplianceOwner', 'isComplianceRoomsCollaborator', 'isComplianceUser'),

    isComplianceOnly: _ember['default'].computed.or('isComplianceOwner', 'isComplianceRoomsCollaborator', 'isComplianceUser'),
    isPlatformOnly: _ember['default'].computed.or('isPlatformOwner', 'isPlatformUser'),
    privileged: _ember['default'].computed.deprecatingAlias('isOwner'),
    persistedInvitations: _ember['default'].computed.filterBy('invitations', 'isNew', false)
  });

  Role.reopenClass({
    // Find or create will attempt to find a matching role given a particular
    // organization and some params.  If a matching role does not exist, one is
    // created. `name`, `type`, and `organization` are requried params.
    findOrCreate: function findOrCreate(params, store) {
      _ember['default'].assert('You must provide an organization to `Role#findOrCreate`', params.organization);
      _ember['default'].assert('You must provide a type to `Role#findOrCreate`', params.type);
      _ember['default'].assert('You must provide a name to `Role#findOrCreate`', params.name);

      return new _ember['default'].RSVP.Promise(function (resolve) {
        params.organization.get('roles').then(function (roles) {
          var role = roles.find(function (role) {
            if (params.name && role.get('name') !== params.name) {
              return false;
            }

            if (params.type && role.get('type') !== params.type) {
              return false;
            }

            return true;
          });

          if (role) {
            resolve(role);
          } else {
            resolve(store.createRecord('role', params));
          }
        });
      });
    }
  });

  exports['default'] = Role;
});