define("diesel/models/organization", ["exports", "ember-data", "diesel/models/base", "ember"], function (exports, _emberData, _dieselModelsBase, _ember) {

  var orgRegex = new RegExp("organizations/([a-zA-Z0-9-]+)");

  var UPGRADE_PLAN_REQUEST_EVENT = "Production Upgrade Request";
  var CREATE_NEW_PRODUCTION_ENVIRONMENT_EVENT = "Customer Created New Production Environment";

  exports.UPGRADE_PLAN_REQUEST_EVENT = UPGRADE_PLAN_REQUEST_EVENT;
  exports.CREATE_NEW_PRODUCTION_ENVIRONMENT_EVENT = CREATE_NEW_PRODUCTION_ENVIRONMENT_EVENT;
  exports["default"] = _dieselModelsBase["default"].extend({
    name: _emberData["default"].attr("string"),
    primaryPhone: _emberData["default"].attr("string"),
    emergencyPhone: _emberData["default"].attr("string"),
    city: _emberData["default"].attr("string"),
    state: _emberData["default"].attr("string"),
    zip: _emberData["default"].attr("string"),
    address: _emberData["default"].attr("string"),
    plan: _emberData["default"].attr("string"),
    opsAlertEmail: _emberData["default"].attr("string"),
    securityAlertEmail: _emberData["default"].attr("string"),
    ssoEnforced: _emberData["default"].attr("boolean"),
    enterprise: _emberData["default"].attr('boolean'),
    users: _emberData["default"].hasMany("user", { async: true }),
    invitations: _emberData["default"].hasMany("invitation", { async: true }),
    roles: _emberData["default"].hasMany("role", { async: true }),
    scimGroups: _emberData["default"].hasMany("scim-group", { async: true }),
    managePermissions: _ember["default"].computed.filterBy("permissions", "scope", "manage"),

    // With the introduction of account-ui, it's possible that a newly created
    // organization does not have a billing detail. In that case, this is set to
    // null and handle the missing data in the UI
    billingDetail: _ember["default"].computed("id", function () {
      var promise = this.store.find("billing-detail", this.get("id")).then(function (billingDetail) {
        return billingDetail;
      },
      // Handle the 404 case
      function (_error) {
        return null;
      });
      return _emberData["default"].PromiseObject.create({ promise: promise });
    }),

    ownerRole: _ember["default"].computed("roles.@each.type", "managePermissions", function () {
      var roles = this.get("roles");
      return roles.filter(function (role) {
        return role.get("type") === "owner";
      }).get("firstObject");
    }),

    complianceOwnerRole: _ember["default"].computed("roles.@each.type", "managePermissions", function () {
      var roles = this.get("roles");
      return roles.filter(function (role) {
        return role.get("type") === "compliance_owner";
      });
    }),

    platformOwnerRole: _ember["default"].computed("roles.@each.type", "managePermissions", function () {
      var roles = this.get("roles");
      return roles.filter(function (role) {
        return role.get("type") === "platform_owner";
      });
    }),

    platformUserRoles: _ember["default"].computed("roles.@each.type", "managePermissions", function () {
      var roles = this.get("roles");
      return roles.filter(function (role) {
        return role.get("type") === "platform_user";
      });
    }),

    complianceUserRoles: _ember["default"].computed("roles.@each.type", "managePermissions", function () {
      var roles = this.get("roles");
      return roles.filter(function (role) {
        return role.get("type") === "platform_user";
      });
    }),

    userRoles: _ember["default"].computed("roles.@each.type", "managePermissions", function () {
      var roles = this.get("roles");
      return roles.filter(function (role) {
        return role.get("type") === "platform_user" || role.get("type") === "compliance_user";
      });
    }),

    // Needed by aptible-ability
    permitsRole: function permitsRole(role, scope) {
      var roleOrganizationHref = role.get("links.organization");
      var match = orgRegex.exec(roleOrganizationHref);
      var roleOrganizationId = match[1];

      var permitted = roleOrganizationId === this.get("id");
      if (scope === "manage") {
        permitted = permitted && role.get("isOwner");
      }
      return permitted;
    }
  });
});